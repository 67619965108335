import { useState } from "react";

import { FileUpload } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useController } from "react-hook-form";

import type { AlertParameters } from "@/components/common/alert/Alert";
import { MAX_SIZE_RASTER_UPLOAD } from "@/constants/map";

type FileInputProps = {
  name: string;
  control: any;
  accept?: string;
  setAlert: (params: AlertParameters) => void;
};

export const FileInput = (props: FileInputProps) => {
  const { control, name, accept, setAlert } = props;
  const { field } = useController({ control, name });
  const [value, setValue] = useState("");
  return (
    <Button variant="contained" component="label" startIcon={<FileUpload />}>
      Upload File
      <input
        hidden
        accept={accept ?? "*"}
        type="file"
        onChange={(e: any) => {
          if (e.target.files[0].size > MAX_SIZE_RASTER_UPLOAD) {
            setAlert({
              content: {
                showAlert: true,
                body: "Maximum file size is 10 MB.",
              },
              properties: { duration: 3000 },
            });
            return;
          }
          setValue(e.target.value);
          field.onChange(e.target.files);
        }}
        required
        value={value}
      />
    </Button>
  );
};
