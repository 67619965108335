import React from "react";

import { Grid, ListItem, ListItemText } from "@mui/material";

import type { SettingsParams } from "@/constants/settings";
import { turnSnakeCaseIntoSpacedText } from "@/utils/string-utils";

export interface SettingsDetailProps {
  params: SettingsParams;
}

export const SettingsConfirmationDetail = (props: SettingsDetailProps) => {
  const { params } = props;

  const getSecondary = (key: string, value: any): string => {
    if (typeof value === "string") return turnSnakeCaseIntoSpacedText(value);
    if (key.toLowerCase() === "center_point")
      return `${value[0]} / ${value[1]}`;
    return value;
  };

  return (
    <Grid container sx={{ borderLeft: "5px solid lightgrey", mt: 2 }}>
      {Object.entries(params).map(([key, value], index) =>
        key === "name" ? null : (
          <Grid item xs={12} key={index}>
            <ListItem sx={{ pb: 0, pt: 0, width: "50%" }}>
              <ListItemText
                primary={turnSnakeCaseIntoSpacedText(key)}
                secondary={getSecondary(key, value)}
                primaryTypographyProps={{ style: { fontWeight: 700 } }}
                secondaryTypographyProps={{ style: { fontSize: 14 } }}
              />
            </ListItem>
          </Grid>
        )
      )}
    </Grid>
  );
};
