interface IMapStyle {
  displayName: string;
  url: string;
}

export enum ArupMapStyle {
  ARUP_STREETS = "mapbox://styles/seanmerrifield/clwkiduzf00r901pn4fcn0bo9?fresh=true",
  STREETS = "mapbox://styles/mapbox/streets-v12",
  OUTDOORS = "mapbox://styles/mapbox/outdoors-v12",
  SATELLITE = "mapbox://styles/mapbox/satellite-v12",
  SATELLITE_STREETS = "mapbox://styles/mapbox/satellite-streets-v12",
  DARK = "mapbox://styles/mapbox/dark-v11",
  LIGHT = "mapbox://styles/mapbox/light-v11",
  AERIAL = "mapbox://styles/seanmerrifield/clbxesvmu000l14lhjbcz9n8o?fresh=true",
}

// Caveat: changing map styles will likely count as a map load (in terms of Mapbox pricing)
export const MAP_STYLES: { [keyof: string]: IMapStyle } = {
  streets: {
    displayName: "Streets",
    url: "mapbox://styles/seanmerrifield/clwkiduzf00r901pn4fcn0bo9?fresh=true",
  },
  aerial: {
    displayName: "Aerial",
    url: "mapbox://styles/seanmerrifield/clbxesvmu000l14lhjbcz9n8o?fresh=true",
  },
};
