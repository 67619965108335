import useSWR, { mutate } from "swr";

import type {
  BodyUploadMapLayerMapLayerPost,
  HazardType,
  ReferenceTypeEnum,
} from "@/apis/services/HazardService";
import { EngineRunStatus } from "@/apis/services/HazardService";

import { useServices } from "./useServices";

export type UploadRasterQueryType = {
  ref_id: string;
  ref_type: ReferenceTypeEnum;
  hazard_type?: HazardType;
  description?: string;
  name: string;
};

export const useUploadRaster = () => {
  const { hazard } = useServices();
  const handleUploadRaster = async (
    query: UploadRasterQueryType,
    data: BodyUploadMapLayerMapLayerPost
  ) => {
    const response = await hazard.map.uploadMapLayer(query as any, data);
    return response.data;
  };
  return handleUploadRaster;
};

export const useRefreshRasterUploadStatus = () => {
  const { hazard } = useServices();
  const handleRefreshRasterUploadStatus = async (
    layerId: string,
    query: { ref_id: string; ref_type: ReferenceTypeEnum }
  ) => {
    const response = await hazard.map.refreshMapLayerStatus(layerId, query);
    response.data.status === EngineRunStatus.COMPLETED &&
      mutate(["useGetMapLayers", response.data.hazard_type]);
    return response.data;
  };
  return handleRefreshRasterUploadStatus;
};

export const useGetMapLayers = (
  ref_id: string,
  hazard_type: HazardType,
  ref_type: ReferenceTypeEnum,
  upload_status: EngineRunStatus
) => {
  const { hazard } = useServices();

  const { data, error } = useSWR(
    ref_id ? ["useGetMapLayers", ref_id, hazard_type] : null,
    async () => {
      const response = await hazard.map.getMapLayers({
        ref_id,
        ref_type,
        hazard_type: hazard_type as any,
        upload_status,
      });
      return response.data;
    }
  );
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
