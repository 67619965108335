import { useState } from "react";

import { Typography } from "@mui/material";

import type {
  AdjacencyMapSetting,
  GroupAssetMapSetting,
  HighestRiskTableSetting,
  InstanceViewEnum,
  RiskMetricsGraphSetting,
  RiskRatingsGraphSetting,
  RiskSurfaceAreaGraphSetting,
  SingleAssetMapSetting,
  AggregateStatisticsSetting,
  BaseDataGridSetting,
  PortfolioMapSetting,
  UpdateOrganizationSettingsRequest,
  SettingNameEnum,
} from "@/apis/services/OrganizationService";
import { Alert } from "@/components/common/alert/Alert";
import type { AlertContent } from "@/components/common/alert/Alert";
import { getErrorDetails } from "@/components/common/alert/alert-utils";
import { Dialog } from "@/components/common/Dialog";
import { useUpdateOrganizationSettings } from "@/hooks/useAllOrganizations";
import {
  useCreateSettingsForView,
  useGetViewSettingsForGroup,
  useUpdateSettingsForView,
} from "@/hooks/useSettings";
import { useUserInfo } from "@/hooks/useUserInfo";

import { SettingsConfirmationDetail } from "./SettingsConfirmationDetail";

export interface IndividualSettings {
  view: InstanceViewEnum;
  component: SettingNameEnum;
  view_id?: string;
  params:
    | SingleAssetMapSetting
    | GroupAssetMapSetting
    | PortfolioMapSetting
    | AdjacencyMapSetting
    | RiskRatingsGraphSetting
    | RiskMetricsGraphSetting
    | HighestRiskTableSetting
    | AggregateStatisticsSetting
    | RiskSurfaceAreaGraphSetting
    | BaseDataGridSetting;
}

export interface SettingsConfirmationPopupProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  settings: IndividualSettings;
}

export const SettingsConfirmationPopup = (
  props: SettingsConfirmationPopupProps
) => {
  const { open, handleClose, title, settings } = props;
  const { data } = useUserInfo();

  const orgId = data?.user?.organization?.id;
  const groupId = settings.view_id;
  const isGroup = !!groupId;
  // we should get either the settings for the group if we have an ID or the entire organization
  // the later is because we would want to update the settings for the portfolio map
  const { data: groupSettings } = useGetViewSettingsForGroup(orgId, groupId);

  const handleCreateSettingsForView = useCreateSettingsForView();
  const handleUpdateSettingsForView = useUpdateSettingsForView();
  const updateOrganizationSettings = useUpdateOrganizationSettings();

  const [isLoading, setIsLoading] = useState(false);
  const [alertContent, setAlertContent] = useState<AlertContent>({});

  const handleCancel = () => {
    handleClose();
  };

  const handleOk = async () => {
    try {
      setIsLoading(true);

      if (isGroup) {
        const api = !!groupSettings
          ? handleUpdateSettingsForView
          : handleCreateSettingsForView;
        await api(data?.user?.organization?.id!, settings);
      } else {
        const updatedPortfolioSettings: UpdateOrganizationSettingsRequest = {
          views: {
            portfolio: {
              settings: {
                [settings.component]: { ...settings.params },
              },
            },
          },
        };
        await updateOrganizationSettings(orgId!, updatedPortfolioSettings);
      }
    } catch (error) {
      console.error("SettingsConfirmationPopup..onSubmit.error :", error);
      setAlertContent({
        showAlert: true,
        body: `There was an error while ${
          !!groupSettings ? "updating" : "creating"
        } the settings.`,
        details: getErrorDetails(error),
      });
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <Dialog
        openDialog={open}
        closeDialog={() => handleCancel()}
        handleAction={() => handleOk()}
        loading={isLoading}
        buttonTitle={`${groupSettings || !groupId ? "Update" : "Create"}`}
        dialogTitle={`${
          groupSettings || !groupId ? "Update" : "Create"
        } settings for ${title}`}
      >
        <Typography>
          The changes will be applied to all users in your organization. Do you
          really want to update the settings?
        </Typography>
        <SettingsConfirmationDetail params={settings.params} />
      </Dialog>

      <Alert content={alertContent} />
    </>
  );
};
