import { classOneRatingColors } from "@/components/risk-ratings/ClassOneRiskRatings";

export const generateClusterProperties = () => {
  const riskLevels: string[] = Object.keys(classOneRatingColors);
  return riskLevels.reduce(
    (acc, cur, i) => ({
      ...acc,
      [`riskLevel${i}`]: ["+", ["case", ["==", ["get", "risk"], cur], 1, 0]],
    }),
    {}
  );
};
