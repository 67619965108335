import type { GeoJSONSourceRaw } from "react-map-gl";

import type { AssetBasicResponse } from "@/apis/services/AssetService";

import { generateClusterProperties } from "./generateClusterProperties";
import type { IAssetsWithRiskLevels } from "./IAssetsWithRiskLevels";

export const createSourceData = (
  assetsWithGeoLocation: IAssetsWithRiskLevels[] | AssetBasicResponse[],
  isCluster?: boolean
): GeoJSONSourceRaw => {
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: assetsWithGeoLocation.map((asset) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: asset.geo_location as number[],
          },
          properties: {
            asset: asset,
            risk: "risk" in asset ? asset.risk : "Not Assessed",
          },
        };
      }),
    },
    ...(isCluster && {
      cluster: true,
      clusterRadius: 20,
      clusterProperties: generateClusterProperties(),
    }),
  };
};
