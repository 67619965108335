import { Tooltip } from "@mui/material";
import type { AxisTickProps } from "@nivo/axes";

import { INDEX_SPLIT } from "@/constants/graphs";
import { truncateString } from "@/utils/string-utils";

export const CustomNameOnAxis = (
  tick: AxisTickProps<string>,
  isHorizontal?: boolean,
  label?: string,
  color?: string
) => {
  const textValue = label ?? tick?.value?.split(INDEX_SPLIT)[0] ?? "";
  return (
    <g
      transform={`translate(${tick.x - 8},${tick.y + (isHorizontal ? 0 : 20)})`}
    >
      <Tooltip title={textValue}>
        <text
          textAnchor="end"
          dominantBaseline="middle"
          transform={!isHorizontal ? "rotate(-30) translate(15,-5)" : ""}
          fontSize={isHorizontal ? 11 : 10}
          fill={color}
        >
          {truncateString(textValue, isHorizontal ? 25 : 20)}
        </text>
      </Tooltip>
    </g>
  );
};
