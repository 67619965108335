import type { BarDatum, ComputedBarDatum } from "@nivo/bar";

interface Props {
  bars: ComputedBarDatum<BarDatum>[];
  highlight: string | string[];
}

export const BarBorder: any = ({ bars, highlight }: Props) => {
  if (!highlight) return <></>;

  return bars.map((bar) => {
    const keys = bar.key.split(".");
    const drawBorder = bar.data.data[`${keys[0]}Hazard`] === highlight;

    if (!drawBorder) return <></>;

    return (
      <>
        <SubgraphBorderLeft bar={bar} />
        <SubgraphBorderTop bar={bar} />
        <SubgraphBorderRight bar={bar} />
        <SubgraphBorderBottom bar={bar} />
      </>
    );
  });
};

const defaultProps: any = {
  stroke: "black",
  strokeWidth: 1,
  style: { pointerEvents: "none" },
};

export const SubgraphBorderLeft = ({ bar }: any) => (
  <line
    {...defaultProps}
    key={bar.key}
    x1={bar.x}
    y1={bar.y}
    x2={bar.x}
    y2={bar.y + bar.height}
  />
);

export const SubgraphBorderTop = ({ bar }: any) => (
  <line
    {...defaultProps}
    key={bar.key}
    x1={bar.x}
    y1={bar.y + bar.height}
    x2={bar.x + bar.width}
    y2={bar.y + bar.height}
  />
);

export const SubgraphBorderRight = ({ bar }: any) => (
  <line
    {...defaultProps}
    key={bar.key}
    x1={bar.x + bar.width}
    y1={bar.y}
    x2={bar.x + bar.width}
    y2={bar.y + bar.height}
  />
);

export const SubgraphBorderBottom = ({ bar }: any) => (
  <line
    {...defaultProps}
    key={bar.key}
    x1={bar.x}
    y1={bar.y}
    x2={bar.x + bar.width}
    y2={bar.y}
  />
);
