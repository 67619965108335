import type { Point } from "geojson";
import type { GeoJSONFeature } from "mapbox-gl";
import { Marker } from "react-map-gl";

import { createDonutChart } from "@/components/map/utils/mapUtils/createDonutChart";

export const createDonutMarkers = (
  features: GeoJSONFeature[]
): Array<typeof Marker> => {
  //Filter out all features that have the same ID
  const featuresFiltered = Object.values(
    Object.fromEntries(
      features
        .filter((feature) => feature.properties?.cluster)
        .map((feature) => [feature.id as number, feature])
    )
  );

  const markers = featuresFiltered
    ?.map((feature: GeoJSONFeature) => {
      const coords = (feature.geometry as Point).coordinates as [
        number,
        number
      ];
      const props = feature.properties;
      if (!props?.cluster) {
        return null;
      }

      const id = props.cluster_id ?? props.id;
      return (
        <Marker key={id} latitude={coords[1]} longitude={coords[0]}>
          {createDonutChart(props)}
        </Marker>
      );
    })
    .filter(Boolean);

  return markers as unknown as (typeof Marker)[];
};
