import React from "react";

import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons/faCloudArrowUp";
import { faGear } from "@fortawesome/free-solid-svg-icons/faGear";
import { faObjectUngroup } from "@fortawesome/free-solid-svg-icons/faObjectUngroup";
import { faSatellite } from "@fortawesome/free-solid-svg-icons/faSatellite";

import { useUserInfo } from "@/hooks/useUserInfo";
import { isAnalyst } from "@/utils/user-utils";

import { MapButton } from "./MapButton";

interface IMapButtonGroupProps {
  isGroup: boolean;
  actions: { [key: string]: React.Dispatch<React.SetStateAction<boolean>> };
}

export const MapButtonGroup = (props: IMapButtonGroupProps) => {
  const { isGroup, actions } = props;
  const { data } = useUserInfo();

  return (
    <>
      <MapButton
        onClick={actions.setIsCluster}
        icon={faObjectUngroup}
        top={160}
      />

      {isAnalyst(data?.user?.roles) && (
        <MapButton
          top={240}
          onClick={actions.setSettingsPopupIsOpen}
          icon={faGear}
          iconSize="lg"
          styling={{
            minWidth: 35,
          }}
        />
      )}
      {isGroup && isAnalyst(data?.user?.roles) ? (
        <MapButton
          top={630}
          onClick={actions.setRasterUploadIsOpen}
          icon={faCloudArrowUp}
        />
      ) : null}
      <MapButton
        top={200}
        onClick={actions.toggleSatellite}
        icon={faSatellite}
        styling={{
          minWidth: 35,
          height: 34,
        }}
        iconSize="lg"
      />
    </>
  );
};
