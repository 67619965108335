import React from "react";

import { Stack, useMediaQuery, useTheme } from "@mui/material";

import { SettingNameEnum } from "@/apis/services/OrganizationService";
import { ConsequenceFilter } from "@/components/asset/filter/ConsequenceFilter";
import { HazardFilter } from "@/components/asset/filter/HazardFilter";
import { prepareListByGroup } from "@/components/high-risks/risk-ratings/helperFunctionsOfIndividualAssetRiskRating";
import { HAZARD_TYPES_BY_GROUP } from "@/components/high-risks/types";
import { useGetFeatureFlags } from "@/hooks/useFlags";
import { useUserInfo } from "@/hooks/useUserInfo";
import { isFlagEnabled } from "@/utils/flags/flags-utils";

type DropdownProps = {
  selectedHazardsUi: any;
  setHazardsAndConsequences: any;
  selectedConsequencesUi: any;
};
export const HazardConsequenceDropdown = (props: DropdownProps) => {
  const {
    selectedHazardsUi,
    setHazardsAndConsequences,
    selectedConsequencesUi,
  } = props;

  const { data: id } = useUserInfo();

  const orgId = id?.user?.organization?.id ?? "";
  const { data: flags } = useGetFeatureFlags(orgId);
  const useOpenSearch = isFlagEnabled(flags, "useOpenSearch");

  const theme = useTheme();
  const isSmallerThanCustomSize = useMediaQuery("(max-width:568px)");

  const styles: any = {
    formStyles: {
      bgcolor: "#fff",
      borderRadius: 2,
      boxShadow: "0 0 10px 2px rgba(0,0,0,.2)",
    },
    selectStyles: {
      "& fieldset": {
        borderColor: "inherit !important",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
  };

  return (
    <Stack
      direction="row"
      display="flex"
      spacing={2}
      width={isSmallerThanCustomSize ? "100%" : undefined}
      pr={isSmallerThanCustomSize ? 2 : undefined}
      justifyContent={isSmallerThanCustomSize ? "space-between" : undefined}
    >
      <HazardFilter
        hazards={prepareListByGroup(Object.keys(HAZARD_TYPES_BY_GROUP), true)}
        value={selectedHazardsUi}
        onChange={(hazards) => {
          setHazardsAndConsequences({ hazards });
        }}
        options={{ showAll: "All Hazards", useOpenSearch: useOpenSearch }}
        styles={styles}
      />

      <ConsequenceFilter
        value={selectedConsequencesUi}
        onChange={(consequences: any) => {
          setHazardsAndConsequences({ consequences });
        }}
        setting={SettingNameEnum.RiskRatingsGraph}
        options={{ showAll: "All Consequences", useOpenSearch: useOpenSearch }}
        styles={styles}
      />
    </Stack>
  );
};
