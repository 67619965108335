import type { AssetBasicResponse } from "@/apis/services/AssetService";
import type { RatingInfo } from "@/apis/services/HazardService";

export const getMapRows = (
  results: RatingInfo[],
  assets: AssetBasicResponse[] | object[]
) => {
  const array = results.map((entry) => {
    const entryId = entry.ref_id || (entry as any).id;

    const name =
      (
        assets?.find(
          (asset: { [key: string]: any }) => asset["id"] === entryId
        ) as { [key: string]: any }
      )?.["name"] || entryId;

    return {
      id: entryId,
      name,
      hazard: entry.hazard ?? "",
      risk: entry.risk_rating,
      consequence: entry.consequence,
    };
  });
  return array;
};
