import { faFileExcel } from "@fortawesome/free-solid-svg-icons/faFileExcel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";

export interface ErrorInformation {
  message: string;
  details?: string;
}

export interface ErrorAnalyticsProps {
  error: ErrorInformation | null;
}

export const ErrorAnalytics = (props: ErrorAnalyticsProps) => {
  const { error } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      data-test="error-analytics-view"
    >
      <FontAwesomeIcon icon={faFileExcel} size="3x" />

      <Typography
        variant="body2"
        sx={{
          pt: 1,
          fontSize: "1.3rem",
          fontWeight: "bold",
          maxWidth: "70%",
          textAlign: "center",
        }}
      >
        {error?.message}
      </Typography>
      <Typography variant="body2" sx={{ maxWidth: "70%" }}>
        {error?.details}
      </Typography>
    </Box>
  );
};
