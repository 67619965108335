import type { LayerProps } from "react-map-gl";

import { classOneRatingColors } from "@/components/risk-ratings/ClassOneRiskRatings";

const stops = Object.entries(classOneRatingColors).map(
  ([category, { color }]) => [category, color]
);

export const clusterLayer = ({ mapParameters }: any): LayerProps => ({
  id: `clusters`,
  type: "circle",
  source: "assets_clustered",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": "rgba(255,255,255, 0.01)",
    "circle-radius": ["step", ["get", "point_count"], 20, 10, 22, 20, 20],
  },
  layout: {
    visibility: parameterMatchesLayerType({ mapParameters, isCluster: true })
      ? "visible"
      : "none",
  },
});

const parameterMatchesLayerType = ({ mapParameters, isCluster }: Props) =>
  (mapParameters.cluster && isCluster) ||
  (!mapParameters.cluster && !isCluster);

type Props = {
  mapParameters: { cluster: boolean };
  isCluster: boolean;
};

export const unclusteredPointLayer = ({
  isCluster,
  mapParameters,
}: Props): LayerProps => ({
  id: `unclustered-point-${isCluster}`,
  type: "circle",
  source: "assets",
  ...(isCluster ? { filter: ["!", ["has", "point_count"]] } : {}),
  paint: {
    "circle-color": {
      property: "risk",
      type: "categorical",
      stops: stops,
    },
    "circle-radius": 6,
    "circle-stroke-width": 1,
    "circle-stroke-color": {
      property: "risk",
      type: "categorical",
      stops: stops,
    },
  },
  layout: {
    visibility: parameterMatchesLayerType({ mapParameters, isCluster })
      ? "visible"
      : "none",
  },
});

export const unclusteredOpaquePointLayer = ({
  isCluster,
  mapParameters,
}: Props): LayerProps => {
  return {
    id: `unclustered-opaque-point-${isCluster}`,
    type: "circle",
    source: "assets",
    ...(isCluster ? { filter: ["!", ["has", "point_count"]] } : {}),
    paint: {
      "circle-color": {
        property: "risk",
        type: "categorical",
        stops: stops,
      },
      "circle-radius": 12,
      "circle-stroke-width": 1,
      "circle-stroke-color": "#fff",
      "circle-opacity": 0.3,
    },
    layout: {
      visibility: parameterMatchesLayerType({ mapParameters, isCluster })
        ? "visible"
        : "none",
    },
  };
};

export const rasterDataLayer = (index: number): LayerProps => ({
  id: `custom-raster-${index}`,
  type: "raster",
  source: `tileset_data-${index}`,
});
