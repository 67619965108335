import type { FeatureCollection, Geometry, GeoJsonProperties } from "geojson";
import type { SourceProps } from "react-map-gl";

import type { MapLayerInfo } from "@/apis/services/HazardService";
import { RiskRatingEnum } from "@/apis/services/HazardService";
import { baseRasterStyleURL } from "@/constants/map";

const catastrophic = ["==", ["get", "risk"], RiskRatingEnum.Catastrophic];
const veryHigh = ["==", ["get", "risk"], RiskRatingEnum.VeryHigh];
const highVeryHigh = ["==", ["get", "risk"], RiskRatingEnum.HighVeryHigh];
const high = ["==", ["get", "risk"], RiskRatingEnum.High];
const mediumHigh = ["==", ["get", "risk"], RiskRatingEnum.MediumHigh];
const medium = ["==", ["get", "risk"], RiskRatingEnum.Medium];
const lowMedium = ["==", ["get", "risk"], RiskRatingEnum.LowMedium];
const low = ["==", ["get", "risk"], RiskRatingEnum.Low];
const veryLow = ["==", ["get", "risk"], RiskRatingEnum.VeryLow];
const negligible = ["==", ["get", "risk"], RiskRatingEnum.Negligible];
const notAssessed = ["==", ["get", "risk"], RiskRatingEnum.NotAssessed];
const plausible = ["==", ["get", "risk"], RiskRatingEnum.Plausible];

type Props = {
  data: FeatureCollection<Geometry, GeoJsonProperties>;
};

export const clusterSource = ({ data }: Props): SourceProps => ({
  id: `assets_clustered`,
  data,
  type: "geojson",
  clusterProperties: {
    [RiskRatingEnum.Catastrophic]: ["+", ["case", catastrophic, 1, 0]],
    [RiskRatingEnum.VeryHigh]: ["+", ["case", veryHigh, 1, 0]],
    [RiskRatingEnum.HighVeryHigh]: ["+", ["case", highVeryHigh, 1, 0]],
    [RiskRatingEnum.High]: ["+", ["case", high, 1, 0]],
    [RiskRatingEnum.MediumHigh]: ["+", ["case", mediumHigh, 1, 0]],
    [RiskRatingEnum.Medium]: ["+", ["case", medium, 1, 0]],
    [RiskRatingEnum.LowMedium]: ["+", ["case", lowMedium, 1, 0]],
    [RiskRatingEnum.Low]: ["+", ["case", low, 1, 0]],
    [RiskRatingEnum.VeryLow]: ["+", ["case", veryLow, 1, 0]],
    [RiskRatingEnum.Negligible]: ["+", ["case", negligible, 1, 0]],
    [RiskRatingEnum.NotAssessed]: ["+", ["case", notAssessed, 1, 0]],
    [RiskRatingEnum.Plausible]: ["+", ["case", plausible, 1, 0]],
  },
  clusterMaxZoom: 15,
  clusterRadius: 50,
  cluster: true,
});

export const unClusteredSource = ({ data }: Props): SourceProps => ({
  id: `assets_unclustered`,
  data,
  type: "geojson",
});

export const rasterDataSource = (
  raster: MapLayerInfo,
  index: number
): SourceProps => ({
  id: `tileset_data-${index}`,
  url: `${baseRasterStyleURL}.${raster.id}?fresh=true`,
  type: "raster",
});
