import { faDrawPolygon, faSave } from "@fortawesome/free-solid-svg-icons";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons/faCircleChevronRight";
import { faExpand } from "@fortawesome/free-solid-svg-icons/faExpand";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons/faFileSignature";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons/faFileUpload";
import { faHouse } from "@fortawesome/free-solid-svg-icons/faHouse";
import { faPlane } from "@fortawesome/free-solid-svg-icons/faPlane";
import { faRoad } from "@fortawesome/free-solid-svg-icons/faRoad";
import { faRotate } from "@fortawesome/free-solid-svg-icons/faRotate";
import { faSatellite } from "@fortawesome/free-solid-svg-icons/faSatellite";
import { faTableCells } from "@fortawesome/free-solid-svg-icons/faTableCells";
import { faTrain } from "@fortawesome/free-solid-svg-icons/faTrain";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";

import { site_colors } from "@/components/adjacency/utils/adjacency-utils";

export const drawStyling = [
  //ACTIVE, in draw mode
  {
    id: "gl-draw-line",
    type: "line",
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["!=", "mode", "simple_select"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#D20C0C",
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-polygon-fill",
    type: "fill",
    filter: [
      "all",
      ["==", "$type", "Polygon"],
      ["!=", "mode", "simple_select"],
    ],
    paint: {
      "fill-color": [
        "case",
        ["==", ["get", "user_line"], "BUILDING"],
        site_colors.building,
        "#ff0000",
      ],
      "fill-outline-color": "#D20C0C",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: [
      "all",
      ["==", "$type", "Polygon"],
      ["==", "mode", "simple_select"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": [
        "case",
        ["==", ["get", "user_line"], "BUILDING"],
        site_colors.building,
        "#ff0000",
      ],
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-polygon-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 3,
      "circle-color": "#fbb03b",
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-halo-active",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#FFF",
    },
  },
  // vertex points
  {
    id: "gl-draw-polygon-and-line-vertex-active",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 3,
      "circle-color": "#D20C0C",
    },
  },
  // INACTIVE (static, already drawn)
  {
    id: "gl-draw-line-static",
    type: "line",
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["==", "mode", "simple_select"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": [
        "case",
        ["==", ["get", "user_line"], "AIRPORT"],
        site_colors.airport,
        ["==", ["get", "user_line"], "RAIL"],
        site_colors.rail,
        site_colors.road,
      ],
      "line-width": 3,
    },
  },
  {
    id: "gl-draw-polygon-fill-static",
    type: "fill",
    filter: [
      "all",
      ["==", "$type", "Polygon"],
      ["==", "mode", "simple_select"],
    ],
    paint: {
      "fill-color": [
        "case",
        ["==", ["get", "user_line"], "BUILDING"],
        site_colors.building,
        ["==", ["get", "user_line"], "SITE"],
        site_colors.site,
        "#ff0000",
      ],
      "fill-outline-color": "#000",
      "fill-opacity": ["case", ["==", ["get", "user_line"], "SITE"], 0, 0.1],
    },
  },
  {
    id: "gl-draw-polygon-stroke-static",
    type: "line",
    filter: [
      "all",
      ["==", "$type", "Polygon"],
      ["==", "mode", "simple_select"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": [
        "case",
        ["==", ["get", "user_line"], "BUILDING"],
        site_colors.building,
        ["==", ["get", "user_line"], "SITE"],
        site_colors.site,
        "#ff0000",
      ],
      "line-width": 3,
    },
  },
  {
    id: "central-point",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "feature"]],
    paint: {
      "circle-radius": 3,
      "circle-color": "black",
    },
  },
  {
    id: "label-on-lines",
    source: "mapbox-gl-draw-cold",
    type: "symbol",
    filter: ["all", ["==", "$type", "LineString"]],
    layout: {
      "text-field": "{user_name} / {user_length} miles",
      "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
      "symbol-placement": "line-center",
      "text-anchor": "bottom",
      "text-size": 14,
      "text-offset": [0, -1],
    },
  },
  {
    id: "label-on-polygons",
    source: "mapbox-gl-draw-cold",
    type: "symbol",
    filter: ["all", ["==", "$type", "Polygon"]],
    layout: {
      "text-field": "{user_name}",
      "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
      "symbol-placement": "point",
      "text-anchor": "bottom",
      "text-size": 14,
      "text-offset": [0, -0.5],
    },
  },
];

export const mapDrawIcons = {
  house: faHouse,
  road: faRoad,
  rail: faTrain,
  satellite: faSatellite,
  plane: faPlane,
  rotate: faRotate,
  expand: faExpand,
  delete: faTrashAlt,
  file: faFileSignature,
  template: faTableCells,
  upload: faFileUpload,
  point: faCircleChevronRight,
  draw: faDrawPolygon,
  save: faSave,
};

export const customButtonStyles = {
  zIndex: 100,
  padding: "5px",
  boxShadow: "none",
  cursor: "pointer",
  border: "2px solid rgba(76,76,76,0.4)",
  borderRadius: "5px",
  backgroundColor: "white",
  minWidth: 34,
  height: 34,
  "&:hover": {
    backgroundColor: "#e7e7e7",
    transitionDuration: "0s",
  },
};
