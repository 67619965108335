import type { PropsWithChildren } from "react";
import React from "react";

import { Box } from "@mui/material";

import type { IAssetsWithRiskLevels } from "@/components/map/utils/mapUtils/IAssetsWithRiskLevels";
import { getRiskRatingColorsByLevel } from "@/components/risk-ratings/ClassOneRiskRatings";

import styles from "./AssetPopUp.module.scss";

export interface AssetPopUpProps {
  asset: IAssetsWithRiskLevels;
}

export const AssetPopUp = (props: PropsWithChildren<AssetPopUpProps>) => {
  const { asset, children } = props;

  const dynamicColour = getRiskRatingColorsByLevel(
    asset.risk || "Not Assessed"
  ).color;

  return (
    <Box
      className={styles.container}
      style={{
        border: `2px solid ${dynamicColour}`,
      }}
    >
      {children}
    </Box>
  );
};
