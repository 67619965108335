import React from "react";

import { Stack, Typography } from "@mui/material";

type ProvideDataContainerProps = {
  icon?: React.ReactNode;
  message: string;
  style?: Object;
};

export const ProvideDataContainer = ({
  icon,
  message,
  style,
}: ProvideDataContainerProps) => {
  return (
    <Stack
      justifyContent="center"
      sx={{
        textAlign: "center",
        height: "100%",
        p: 2,
        border: "2px solid #9E9E9E",
        color: "primary.light",
        borderRadius: 2,
        ...style,
      }}
      alignItems="center"
    >
      {icon}
      <Typography
        variant="body2"
        component="span"
        sx={{ mt: icon ? 1 : 0 }}
        data-test="provide-data-container-text"
      >
        {message}
      </Typography>
    </Stack>
  );
};
