import { RiskRatingEnum } from "@/apis/services/HazardService";
import { classOneRatingColors } from "@/components/risk-ratings/ClassOneRiskRatings";

const donutSegment = (
  start: number,
  end: number,
  r: number,
  r0: number,
  color: string,
  index: number
) => {
  if (end - start === 1) end -= 0.00001;
  const a0 = 2 * Math.PI * (start - 0.25);
  const a1 = 2 * Math.PI * (end - 0.25);
  const x0 = Math.cos(a0),
    y0 = Math.sin(a0);
  const x1 = Math.cos(a1),
    y1 = Math.sin(a1);
  const largeArc = end - start > 0.5 ? 1 : 0;

  // draw an SVG path
  return (
    <path
      key={`${start}-${end}-${r}-${r0}-${color}-${index}`}
      d={`M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${
        r + r * y0
      } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${
        r + r0 * x1
      } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${
        r + r0 * y0
      }`}
      fill={color}
    />
  );
};

export const createDonutChart = (props: any) => {
  const offsets = [];
  //Add a 0 because RiskRatingEnum doesn't have the 'Extreme' value
  const counts = [
    0,
    ...Object.values(RiskRatingEnum).map((r) => props[r] || 0),
  ];
  const colors: string[] = Object.values(classOneRatingColors).map(
    (c) => c.color
  );

  let total = 0;
  for (const count of counts) {
    offsets.push(total);
    total += count;
  }
  const fontSize = total >= 5 ? 20 : total >= 3 ? 16 : total >= 2 ? 14 : 12; // this is the font size of the group number (e.g. if the number of assets in group is more than 5 , font size is 20)
  const r = total >= 5 ? 40 : total >= 3 ? 30 : total >= 2 ? 22 : 16; // here manages the radius of the group circle (e.g. if the number of assets in group is more than 5, radius is 40 px)
  const r0 = Math.round(r * 0.6);
  const w = r * 2;

  const segments = [] as any;
  for (let i = 0; i < counts.length; i++) {
    if (counts[i] > 0) {
      segments.push(
        donutSegment(
          offsets[i] / total,
          (offsets[i] + counts[i]) / total,
          r,
          r0,
          colors[i],
          i
        )
      );
    }
  }
  return (
    <div>
      <svg
        width={w}
        height={w}
        viewBox={`0 0 ${w} ${w}`}
        textAnchor="middle"
        style={{ font: `${fontSize}px sans-serif`, display: "block" }}
      >
        {segments}
        <circle cx={r} cy={r} r={r0} fill="white" />
        <text dominantBaseline="central" transform={`translate(${r}, ${r})`}>
          {total.toLocaleString()}
        </text>
      </svg>
    </div>
  );
};
