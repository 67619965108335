import { useContext, useEffect, useRef, useState } from "react";
import React from "react";

import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { ExpandCircleDown, AddLocationAlt } from "@mui/icons-material";
import {
  Box,
  Collapse,
  debounce,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import type { FeatureCollection, Geometry, GeoJsonProperties } from "geojson";
import type { GeoJSONSource, GeoJSONFeature } from "mapbox-gl";
import mapboxgl from "mapbox-gl";
import { usePathname } from "next/navigation";
import type {
  MapLayerMouseEvent,
  MapRef,
  Marker,
  ViewState,
} from "react-map-gl";
import {
  Map,
  Source,
  Layer,
  NavigationControl,
  GeolocateControl,
  Popup,
  useControl,
} from "react-map-gl";

import type { AssetBasicResponse } from "@/apis/services/AssetService";
import type { RiskRatingHazardsEnum } from "@/apis/services/HazardService";
import {
  AssessmentTypeEnum,
  EngineRunStatus,
  ReferenceTypeEnum,
} from "@/apis/services/HazardService";
import type { AlertParameters } from "@/components/common/alert/Alert";
import { Alert, EMPTY_ALERT } from "@/components/common/alert/Alert";
import { ProvideDataContainer } from "@/components/common/ProvideDataContainer";
import type { QueryValues } from "@/components/common/RiskRCPScenarioTimeHorizonDropdown";
import { RiskRCPScenarioTimeHorizonDropdown } from "@/components/common/RiskRCPScenarioTimeHorizonDropdown";
import { HazardConsequenceDropdown } from "@/components/high-risks/HazardConsequenceDropdown";
import { useHazardsAndConsequences } from "@/components/high-risks/useHazardsAndConsequences";
import { MenuCollapsedContext } from "@/components/layouts/PortfolioLayout";
import { GroupAssetPopUp } from "@/components/map/AssetPopUp/GroupAssetPopUp";
import type { MapParameters } from "@/components/map/BaseMap/BaseMap.types";
import { MapButtonGroup } from "@/components/map/MapButtonGroup";
import { UploadRasterPopup } from "@/components/map/UploadRasterPopup";
import { calculateCentralCoordinateOfAssets } from "@/components/map/utils/mapUtils/calculateCentralCoordinateOfAssets";
import { convertAssetCoordinates } from "@/components/map/utils/mapUtils/convertAssetCoordinates";
import { createSourceData } from "@/components/map/utils/mapUtils/createSourceData";
import { filterAssetsByValidGeolocation } from "@/components/map/utils/mapUtils/filterAssetsByValidGeolocation";
import { getFirstLayerId } from "@/components/map/utils/mapUtils/getFirstLayerId";
import { getMapRows } from "@/components/map/utils/mapUtils/getMapRows";
import type {
  HighRiskTableRow,
  IAssetsWithRiskLevels,
} from "@/components/map/utils/mapUtils/IAssetsWithRiskLevels";
import { MAP_STYLES } from "@/components/map/utils/mapUtils/mapStyles";
import { apiKeys } from "@/config";
import { MAP_TEXT } from "@/constants/informativeTexts";
import { ALL_CONSEQUENCES, ALL_HAZARDS } from "@/constants/risks";
import { useGetFeatureFlags } from "@/hooks/useFlags";
import { useGetMapLayers } from "@/hooks/useRaster";
import { useSearchRiskRatingFunction } from "@/hooks/useRiskRatings";
import { useUserInfo } from "@/hooks/useUserInfo";
import { filterOutHazardRating } from "@/utils/filter-utils";
import { isFlagEnabled } from "@/utils/flags/flags-utils";

import { createDonutMarkers } from "./createDonutMarkers";
import {
  unclusteredPointLayer,
  unclusteredOpaquePointLayer,
  clusterLayer,
  rasterDataLayer,
} from "./layers";
import SettingsConfirmationPopUp from "./SettingsConfirmationPopup";
import { clusterSource, rasterDataSource, unClusteredSource } from "./source";

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";

const MAP_MIN_HEIGHT = 300;
const MAP_MAX_HEIGHT = 700;
const PREVIEW_HEIGHT = 430;

type Props = {
  groupId?: string;
  mapSettings: { [key: string]: any };
  assets?: AssetBasicResponse[];
};

type PopupInfoProps = {
  longitude: number;
  latitude: number;
  asset: IAssetsWithRiskLevels;
} | null;

type ViewportProps = {
  longitude: number | undefined;
  latitude: number | undefined;
  zoom: number;
};

const setFeaturesDebounced = debounce(
  (mapRef: any, setFeatures: (s: GeoJSONFeature[]) => void) => {
    const features = mapRef?.current?.querySourceFeatures("assets_clustered");
    setFeatures(features);
  },
  350
);

const styles = (theme: any) => {
  return {
    backgroundColor: "#fff",
    boxShadow: "0 0 10px 2px rgba(0,0,0,.2)",
    "& fieldset": {
      borderColor: "inherit !important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  };
};

const GroupMap = (props: Props) => {
  const { groupId, mapSettings, assets } = props;

  const pathname = usePathname();
  const isPreview = pathname.includes("preview");
  const MIN_HEIGHT = isPreview ? PREVIEW_HEIGHT : MAP_MIN_HEIGHT;

  const theme = useTheme();
  const mapRef = useRef<MapRef>(null);

  const { menuCollapsed } = useContext(MenuCollapsedContext);

  const { data: id } = useUserInfo();
  const orgId = id?.user?.organization?.id ?? "";
  const { data: flags } = useGetFeatureFlags(orgId);
  const useOpenSearch = isFlagEnabled(flags, "useOpenSearch");

  const [mapRows, setMapRows] = useState<HighRiskTableRow[]>([]);
  const assetsWithGeoLocation = convertAssetCoordinates(
    filterAssetsByValidGeolocation(assets || [], mapRows)
  ) as IAssetsWithRiskLevels[];
  const sourceData = createSourceData(assetsWithGeoLocation);
  const data = sourceData.data as FeatureCollection<
    Geometry,
    GeoJsonProperties
  >;

  const [isExpanded, setIsExpanded] = useState(false);
  const [popupInfo, setPopupInfo] = useState<PopupInfoProps>(null);
  const [rasterUploadIsOpen, setRasterUploadIsOpen] = useState(false);
  const [settingsPopupIsOpen, setSettingsPopupIsOpen] = useState(false);
  const [alert, setAlert] = useState<AlertParameters>({ ...EMPTY_ALERT });
  const [mapParameters, setMapParameters] = useState<MapParameters>({
    zoom: 5,
    center: calculateCentralCoordinateOfAssets(assetsWithGeoLocation)?.geometry
      ?.coordinates,
    cluster: true,
    style: MAP_STYLES.streets.url,
  });
  const [donutMarkers, setDonutMarkers] = useState([] as (typeof Marker)[]);
  const [firstLayerId, setFirstLayerId] = useState<string>();
  const [features, setFeatures] = useState<GeoJSONFeature[]>();
  const [queryValues, setQueryValues] = useState<QueryValues>({
    climateScenario: "all",
    timeHorizon: "all",
    assessmentType: AssessmentTypeEnum.CURRENT,
  });

  const {
    selectedHazards,
    selectedConsequences,
    selectedHazardsUi,
    selectedConsequencesUi,
    setHazardsAndConsequences,
  } = useHazardsAndConsequences({
    defaultConsequences: [ALL_CONSEQUENCES],
    defaultHazards: [ALL_HAZARDS],
  });

  const hazardQuery =
    selectedHazards[0] === ALL_HAZARDS
      ? []
      : [selectedHazards[0] as RiskRatingHazardsEnum];

  const consequenceQuery =
    selectedConsequences[0] === ALL_CONSEQUENCES
      ? []
      : [selectedConsequences[0] as any];
  const climateScenarioQuery =
    queryValues.climateScenario === "all"
      ? undefined
      : queryValues.climateScenario;
  const timeHorizonQuery =
    queryValues.timeHorizon === "all" ? undefined : queryValues.timeHorizon;

  const { data: highTideResult, isError: errorHighTide } =
    useSearchRiskRatingFunction(
      {
        ref_ids: assets?.map(({ id }: any) => id) || [],
        hazards: hazardQuery,
        consequences: consequenceQuery,
      },
      {
        assessmentType: queryValues.assessmentType,
        climateScenario: climateScenarioQuery,
        timeHorizon: timeHorizonQuery,
      }
    );

  const { data: rasterData } = useGetMapLayers(
    groupId ?? "",
    selectedHazards[0] as any,
    ReferenceTypeEnum.GROUP,
    EngineRunStatus.COMPLETED
  );

  // Note: The mapSettings stores the longitude and latitude in the reverse order
  const initialViewport: ViewportProps = {
    longitude: mapSettings?.center_point?.[1] ?? mapParameters.center?.[0],
    latitude: mapSettings?.center_point?.[0] ?? mapParameters.center?.[1],
    zoom: mapSettings?.zoom_level ?? mapParameters.zoom,
  };

  const onViewChange = ({ viewState }: { viewState: ViewState }) => {
    setMapParameters({
      ...mapParameters,
      zoom: viewState.zoom,
      center: [viewState.longitude, viewState.latitude],
    });
  };

  useEffect(() => {
    if (!highTideResult || highTideResult.length === 0) {
      setMapRows([]);
      return;
    }
    // filter out the hazard rating consequences since we don't want to see those on the map
    const filtered = highTideResult.filter(filterOutHazardRating);
    const result = getMapRows(filtered, assets || []);

    setMapRows(result);
  }, [highTideResult]);

  useEffect(() => {
    const donutMarkers = features?.length ? createDonutMarkers(features) : [];
    setDonutMarkers(donutMarkers);
  }, [features]);

  useEffect(() => {
    if (mapRef) setFeaturesDebounced(mapRef, setFeatures);
  }, [mapParameters, mapRows]);

  const onLoad = () => {
    setMapParameters({
      ...mapParameters,
      cluster: mapSettings.clustering !== "decluster",
      style:
        mapSettings.background_map === "satellite"
          ? MAP_STYLES.aerial.url
          : MAP_STYLES.streets.url,
    });
    // Have to convert to any because react-map-gl is using older types compared to mapbox-gl
    const id = getFirstLayerId(mapRef?.current?.getStyle().layers as any);
    setFirstLayerId(id);

    if (mapSettings.center_point && mapSettings.zoom_level) {
      const newCenter: [number, number] = [
        mapSettings.center_point?.[1],
        mapSettings.center_point?.[0],
      ];
      mapRef?.current?.easeTo({
        center: newCenter,
        zoom: mapSettings.zoom_level,
        duration: 1000,
      });
      return;
    }

    fitBounds();
  };

  const fitBounds = () => {
    const mappedGeoLocations = assetsWithGeoLocation.map(
      ({ geo_location }) => ({
        latitude: geo_location?.[1],
        longitude: geo_location?.[0],
      })
    );

    const bounds = mappedGeoLocations.reduce(
      (bounds, feature) => {
        return {
          north: Math.min(55, Math.max(bounds.north, feature.latitude || 0)),
          south: Math.max(-42, Math.min(bounds.south, feature.latitude || 0)),
          east: Math.max(bounds.east, feature.longitude || 0),
          west: Math.min(bounds.west, feature.longitude || 0),
        };
      },
      {
        north: -Infinity,
        south: Infinity,
        east: -Infinity,
        west: Infinity,
      }
    );

    mapRef?.current?.fitBounds(
      [
        [bounds.west, bounds.south],
        [bounds.east, bounds.north],
      ],
      {
        padding: 60,
        maxZoom: mapSettings.zoom_level,
        minZoom: mapSettings.zoom_level,
        offset: [-15, 10],
        linear: true,
        duration: 1000,
      }
    );
  };

  const onClick = (event: MapLayerMouseEvent) => {
    const feature = event.target.queryRenderedFeatures(event.point)[0];
    const geometry = feature?.geometry;
    if (geometry?.type !== "Point") return;

    const clusterId = feature?.properties?.cluster_id;
    const isCluster = mapParameters.cluster;
    const source = `assets_${isCluster ? "clustered" : "unclustered"}`;
    const mapboxSource = mapRef?.current?.getSource(source) as GeoJSONSource;

    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err || !zoom || !isExpanded) return;

      mapRef?.current?.easeTo({
        center: geometry?.coordinates as [number, number],
        zoom,
        duration: 500,
      });
    });

    if (feature.properties?.cluster || !feature.properties?.asset) return;

    if (popupInfo) {
      setPopupInfo(null);
      return;
    }

    setPopupInfo({
      longitude: geometry.coordinates[0],
      latitude: geometry.coordinates[1],
      asset: JSON.parse(feature.properties.asset),
    });
  };

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const GeoSearchControl = (props: any) => {
    useControl(
      () => {
        const ctrl: any = new MapboxGeocoder(props);
        return ctrl;
      },
      {
        position: props.position,
      }
    );

    return null;
  };

  if (assetsWithGeoLocation?.length < 1) {
    return (
      <ProvideDataContainer
        style={{ height: MAP_MIN_HEIGHT }}
        icon={<AddLocationAlt />}
        message={MAP_TEXT.PROVIDE_DATA}
      />
    );
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Collapse in={isExpanded} collapsedSize={MIN_HEIGHT}>
        <Box sx={{ height: MAP_MAX_HEIGHT }}>
          <Map
            initialViewState={initialViewport}
            mapboxAccessToken={apiKeys.mapBoxToken}
            mapStyle={mapParameters.style}
            style={{
              display: "flex",
              height: isExpanded ? MAP_MAX_HEIGHT : MIN_HEIGHT,
            }}
            onClick={onClick}
            key={`groupmap-expand-state-${isExpanded.toString()}-${menuCollapsed}`}
            ref={mapRef}
            onLoad={onLoad}
            onZoom={onViewChange}
            onDrag={onViewChange}
            attributionControl={isExpanded}
            interactive={isExpanded}
          >
            {isExpanded && (
              <>
                <GeoSearchControl
                  accessToken={apiKeys.mapBoxToken}
                  mapboxgl={mapboxgl}
                  zoom={12}
                  position="top-right"
                  style={{ position: "absolute", marginTop: 1000 }}
                />
                <NavigationControl
                  position="top-right"
                  style={{
                    position: "absolute",
                    top: 55,
                    right: 0,
                  }}
                />
                {!!groupId && (
                  <GeolocateControl
                    position="top-right"
                    style={{
                      position: "absolute",
                      top: 280,
                      right: 0,
                    }}
                  />
                )}

                {popupInfo && (
                  <Popup
                    longitude={popupInfo.longitude}
                    latitude={popupInfo.latitude}
                    onClose={() => setPopupInfo(null)}
                    maxWidth="800px"
                  >
                    <GroupAssetPopUp
                      asset={popupInfo.asset}
                      groupId={groupId}
                      consequence={selectedConsequences[0]}
                    />
                  </Popup>
                )}

                <MapButtonGroup
                  actions={{
                    setIsCluster: () =>
                      setMapParameters({
                        ...mapParameters,
                        cluster: !mapParameters.cluster,
                      }),
                    setRasterUploadIsOpen,
                    setSettingsPopupIsOpen,
                    toggleSatellite: () => {
                      setMapParameters({
                        ...mapParameters,
                        style:
                          mapParameters.style === MAP_STYLES.streets.url
                            ? MAP_STYLES.aerial.url
                            : MAP_STYLES.streets.url,
                      });
                    },
                  }}
                  isGroup={!!groupId}
                />
              </>
            )}

            <Source {...clusterSource({ data })}>
              <Layer {...clusterLayer({ mapParameters })} />
              <Layer
                {...unclusteredOpaquePointLayer({
                  isCluster: true,
                  mapParameters,
                })}
              />
              <Layer
                {...unclusteredPointLayer({ isCluster: true, mapParameters })}
              />
            </Source>

            <Source {...unClusteredSource({ data })}>
              <Layer
                {...unclusteredOpaquePointLayer({
                  isCluster: false,
                  mapParameters,
                })}
              />
              <Layer
                {...unclusteredPointLayer({ isCluster: false, mapParameters })}
              />
            </Source>

            {rasterData?.map((raster, index) => (
              <Source
                key={`raster-source-${raster.id}`}
                {...rasterDataSource(raster, index)}
              >
                <Layer
                  key={`raster-layer-${index}`}
                  beforeId={firstLayerId}
                  {...rasterDataLayer(index)}
                />
              </Source>
            ))}

            <SettingsConfirmationPopUp
              settingsPopupIsOpen={settingsPopupIsOpen}
              handleClose={() => setSettingsPopupIsOpen(false)}
              groupId={groupId}
              zoomLevel={mapParameters.zoom}
              center={mapParameters.center}
              isCluster={mapParameters.cluster}
              isSatellite={mapParameters.style === MAP_STYLES.aerial.url}
            />

            {!!groupId && (
              <UploadRasterPopup
                open={rasterUploadIsOpen}
                handleClose={() => setRasterUploadIsOpen(false)}
                refId={groupId}
                setAlert={setAlert}
                selectedHazardsUi={selectedHazardsUi}
                setHazardsAndConsequences={setHazardsAndConsequences}
              />
            )}
            <Alert content={alert.content} properties={alert.properties} />
            {donutMarkers}
          </Map>
        </Box>
      </Collapse>
      {assetsWithGeoLocation.length > 0 && isExpanded && (
        <Stack
          direction="row"
          spacing={2}
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            zIndex: 2,
          }}
        >
          <HazardConsequenceDropdown
            selectedHazardsUi={selectedHazardsUi}
            setHazardsAndConsequences={setHazardsAndConsequences}
            selectedConsequencesUi={selectedConsequencesUi}
          />
          <RiskRCPScenarioTimeHorizonDropdown
            queryValues={queryValues}
            setQueryValues={setQueryValues}
            options={{ styles: styles(theme), useOpenSearch: useOpenSearch }}
          />

          {errorHighTide && (
            <Typography color="error">
              There was an error while retrieving the highest risk assets
            </Typography>
          )}
        </Stack>
      )}
      {!isPreview && (
        <Box position="absolute" bottom={-12} left="50%">
          <IconButton
            onClick={handleExpandClick}
            style={{
              transform: isExpanded ? "rotate(180deg)" : "",
              backgroundColor: "white",
              fontSize: "25px",
              zIndex: 10,
              padding: 1,
            }}
          >
            <ExpandCircleDown style={{ fontSize: "20px" }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default GroupMap;
