import { useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Modal,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Controller, useWatch, useForm } from "react-hook-form";

import type {
  AllHazardsEnum,
  RiskRatingHazardsEnum,
} from "@/apis/services/HazardService";
import {
  EngineRunStatus,
  ReferenceTypeEnum,
} from "@/apis/services/HazardService";
import { POLL_TIMEOUT_IN_MS_FOR_RASTER } from "@/components/assessment/constants";
import { HazardFilter } from "@/components/asset/filter/HazardFilter";
import type { AlertParameters } from "@/components/common/alert/Alert";
import { getErrorDetails } from "@/components/common/alert/alert-utils";
import { FileInput } from "@/components/files/FileInput";
import { prepareListByGroup } from "@/components/high-risks/risk-ratings/helperFunctionsOfIndividualAssetRiskRating";
import { HAZARD_TYPES_BY_GROUP } from "@/components/high-risks/types";
import {
  useRefreshRasterUploadStatus,
  useUploadRaster,
} from "@/hooks/useRaster";
import type { UploadRasterQueryType } from "@/hooks/useRaster";
import { getCardPopoverStyle } from "@/styles/CardPopover";
import { stopPropagation } from "@/utils/utils";

type UploadRasterFormValues = {
  description: string;
  files: FileList;
  name: string;
};

export type AllHazardTypes = RiskRatingHazardsEnum | AllHazardsEnum;

export interface UploadRasterPopupProps {
  open: boolean;
  handleClose: () => void;
  refId: string;
  setAlert: React.Dispatch<React.SetStateAction<AlertParameters>>;
  selectedHazardsUi: any;
  setHazardsAndConsequences: any;
}

export const UploadRasterPopup = (props: UploadRasterPopupProps) => {
  const {
    open,
    handleClose,
    refId,
    setAlert,
    selectedHazardsUi,
    setHazardsAndConsequences,
  } = props;
  const handleUploadRaster = useUploadRaster();
  const handleRefreshRasterUploadStatus = useRefreshRasterUploadStatus();

  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, control, reset } = useForm<UploadRasterFormValues>({
    defaultValues: { name: "", description: "" },
  });

  const rasterFiles = useWatch({ control, name: "files" });

  const handleError = (body: string, details: string[]) => {
    setAlert({
      content: {
        showAlert: true,
        body,
        details,
      },
    });
  };

  const handleEndTimeout = (timer: NodeJS.Timeout) => {
    clearTimeout(timer);
    setIsLoading(false);
    handleClose();
  };

  let timer: NodeJS.Timeout;
  const pollStatusOfUpload = (id: string) => {
    return (timer = setTimeout(async () => {
      try {
        const statusResponse = await handleRefreshRasterUploadStatus(id, {
          ref_id: refId,
          ref_type: ReferenceTypeEnum.GROUP,
        });
        switch (statusResponse.status) {
          case EngineRunStatus.ERROR:
            handleEndTimeout(timer);
            throw new Error(
              "There is an error while uploading the file to mapbox."
            );
          case EngineRunStatus.COMPLETED:
            handleEndTimeout(timer);
            setAlert({
              content: {
                showAlert: true,
                body: "Raster file was uploaded.",
              },
              properties: {
                severity: "success",
                duration: 3000,
              },
            });
            reset();
            break;

          default:
            pollStatusOfUpload(id);
            break;
        }
      } catch (error) {
        handleEndTimeout(timer);
      }
    }, POLL_TIMEOUT_IN_MS_FOR_RASTER));
  };

  const onSubmit = async (data: UploadRasterFormValues) => {
    const query: UploadRasterQueryType = {
      ref_id: refId,
      ref_type: ReferenceTypeEnum.GROUP,
      description: data.description,
      hazard_type: selectedHazardsUi[0],
      name: data.name,
    };
    try {
      setIsLoading(true);
      const response = await handleUploadRaster(query, { file: data.files[0] });
      pollStatusOfUpload(response.id);
    } catch (error) {
      console.error("Raster upload error:", error);
      handleError(
        "There was an error while uploading the custom raster file.",
        getErrorDetails(error)
      );
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)} onFocus={stopPropagation}>
        <Card sx={getCardPopoverStyle(true)} onClick={stopPropagation}>
          <CardHeader title="Upload raster" />
          <Divider />
          <CardContent
            sx={{
              mt: 1,
              overflow: "auto",
              maxWidth: "1500px",
              minWidth: "1000px",
            }}
          >
            <Stack spacing={2}>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <FileInput
                    name="files"
                    control={control}
                    accept=".tiff, .geotiff., .tif"
                    setAlert={setAlert}
                  />
                  {rasterFiles?.length > 0 && (
                    <Typography variant="body2">
                      {rasterFiles[0].name}
                    </Typography>
                  )}
                </Stack>

                <Controller
                  name="name"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <TextField variant="outlined" label="Name" {...field} />
                  )}
                />
                <Controller
                  name="description"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Description"
                      multiline
                      rows={3}
                      {...field}
                    />
                  )}
                />
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography>Hazard Type :</Typography>
                  <HazardFilter
                    value={selectedHazardsUi}
                    onChange={(hazards) =>
                      setHazardsAndConsequences({ hazards })
                    }
                    hazards={prepareListByGroup(
                      Object.keys(HAZARD_TYPES_BY_GROUP),
                      true
                    )}
                    options={{ showAggregate: true }}
                    styles={{
                      selectStyles: {
                        "& fieldset": {
                          borderColor: "inherit !important",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: `${theme.palette.primary.main} !important`,
                        },
                      },
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Box mt={2}>
              <Alert severity="warning">
                <Typography variant="body2">
                  - Only 8-bit tiffs are supported. The coordinate reference
                  system should be EPSG:3857 (WGS84 Web Mercator).
                </Typography>
                <Typography variant="body2">
                  - If you would like styling added to the raster output, export
                  the tiff as a rendered image instead of raw data.
                </Typography>
              </Alert>
            </Box>
          </CardContent>
          <CardActions>
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              justifyContent="flex-end"
              sx={{ width: "100%", mr: 1, mb: 1 }}
            >
              <Button onClick={handleClose} disabled={isLoading}>
                Cancel
              </Button>
              <LoadingButton type="submit" loading={isLoading} size="medium">
                Upload
              </LoadingButton>
            </Stack>
          </CardActions>
        </Card>
      </form>
    </Modal>
  );
};
