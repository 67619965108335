import {
  RiskAppetite,
  type AdjacencyRiskResponseApi,
} from "@/apis/services/HazardService";

export const site_colors: { [keyof: string]: string } = {
  site: "#efbbff",
  building: "#d42c3a",
  airport: "#2c3dd4",
  rail: "#06FF00",
  road: "#F3950D",
  template: "#3498DB",
};

export const tableHeaderStyling = {
  pl: 2,
  py: 1,
  fontSize: "1em",
  fontWeight: "bold",
  backgroundColor: "#F5F6F8",
};

export const groupRailAndRoadData = (array: AdjacencyRiskResponseApi[]) => {
  return array.reduce((agg: any[], cur) => {
    const riskRatingAverse = cur.risk_rating_data.find(
      (risk) => risk.risk_appetite === RiskAppetite.AVERSE
    );
    const existingLine = agg.find(
      (line) => line?.adjacent_site_id === cur.adjacent_site_id
    );
    if (existingLine) {
      // eslint-disable-next-line no-unused-vars
      const { subhazard_type, risk_rating_data, ...rest } = existingLine; // Exclude unnecessary properties
      agg[agg.length - 1] = {
        ...rest,
        resultData: [
          ...existingLine.resultData,
          {
            hazardName: cur.subhazard_type,
            riskRating: riskRatingAverse!.risk_rating,
            calculation_details: cur.calculation_details,
          },
        ],
      };
    } else {
      // eslint-disable-next-line no-unused-vars
      const { subhazard_type, risk_rating_data, ...rest } = cur; // Exclude unnecessary properties
      agg.push({
        ...rest,
        resultData: [
          {
            hazardName: cur.subhazard_type,
            riskRating: riskRatingAverse!.risk_rating,
            calculation_details: cur.calculation_details,
          },
        ],
      });
    }
    return agg;
  }, []);
};
