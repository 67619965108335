import {
  InstanceViewEnum,
  ClusterEnum,
  BackgroundMapEnum,
  SettingNameEnum,
} from "@/apis/services/OrganizationService";
import { SettingsConfirmationPopup } from "@/components/map/SettingsConfirmationPopUp";

type Props = {
  settingsPopupIsOpen: boolean;
  handleClose: () => void;
  groupId?: string;
  zoomLevel: number;
  center?: [number, number];
  isCluster: boolean;
  isSatellite: boolean;
};
const SettingsConfirmationPopUp = ({
  settingsPopupIsOpen,
  handleClose,
  groupId,
  zoomLevel,
  center,
  isCluster,
  isSatellite,
}: Props) => (
  <SettingsConfirmationPopup
    title={`${groupId ? "Group" : "Portfolio"} Map`}
    open={settingsPopupIsOpen}
    handleClose={handleClose}
    settings={{
      view: InstanceViewEnum.Group,
      component: groupId
        ? SettingNameEnum.GroupAssetMap
        : SettingNameEnum.PortfolioMap,
      view_id: groupId,
      params: {
        name: groupId
          ? SettingNameEnum.GroupAssetMap
          : SettingNameEnum.PortfolioMap,
        zoom_level: Math.round(zoomLevel),
        center_point: center ? [...center].reverse() : [],
        clustering: isCluster ? ClusterEnum.Bubble : ClusterEnum.Decluster,
        background_map: isSatellite
          ? BackgroundMapEnum.Satellite
          : BackgroundMapEnum.Street,
      },
    }}
  />
);

export default SettingsConfirmationPopUp;
