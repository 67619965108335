import type { AssetBasicResponse } from "@/apis/services/AssetService";

export const convertAssetCoordinates = (assets: AssetBasicResponse[]) => {
  return assets.map((asset) => {
    return {
      ...asset,
      geo_location: [asset.longitude!, asset.latitude!],
    };
  });
};
