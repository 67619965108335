import centerTurf from "@turf/center";
import type { Position } from "@turf/helpers";
import { points } from "@turf/helpers";

import type { AssetBasicResponse } from "@/apis/services/AssetService";

export const calculateCentralCoordinateOfAssets = (
  assets: AssetBasicResponse[]
) => {
  if (!assets || assets.length === 0) {
    return [] as any;
  }

  const positions = assets.map((asset) => asset.geo_location) as Position[];
  const features = points(positions);
  const center = centerTurf(features);
  return center;
};
