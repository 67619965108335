import type { AssetBasicResponse } from "@/apis/services/AssetService";
import { hasGeoLocation } from "@/utils/assets/assets-utils";

import type {
  HighRiskTableRow,
  IAssetsWithRiskLevels,
} from "./IAssetsWithRiskLevels";

export const filterAssetsByValidGeolocation = (
  assets?: AssetBasicResponse[],
  rows?: HighRiskTableRow[]
) => {
  if (!assets || assets.length === 0) return [];

  const filteredAssets = assets.filter((a) =>
    hasGeoLocation(a?.latitude, a?.longitude)
  );
  const validAssets: AssetBasicResponse[] = filteredAssets.map((asset) => {
    return {
      ...asset,
    };
  });

  if (!rows || rows.length === 0) return validAssets;

  const assetsWithHazards: IAssetsWithRiskLevels[] = validAssets.map(
    (asset: AssetBasicResponse) => {
      const hazard: HighRiskTableRow = rows?.find((r) => r.id === asset.id)!;
      return {
        ...asset,
        ...hazard,
      };
    }
  );
  return assetsWithHazards;
};
